import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_flowForecasting.scss";
import ogImage from "../images/OG_Image.png";
import flowForecasts from "../images/flowForecasts.png";
import modelAccuracy from "../images/modelAccuracy.png";

const FlowForecasting = () => (
  <Layout>
    <SEO
      title="Flow Forecasting"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "flow forecasting",
        "product",
        "feature",
        "analytics",
      ]}
      description="Flowspring forecasts asset flows at the shareclass level for a very high degree of accuracy."
    />
    <div className="content">
      <div className="flowForecastingTitle">
        {"If It's Not "}
        <span className="accent">Forward-Looking</span>
        {", It's Not "}
        <span className="accent">Actionable</span>
      </div>
      <div className="flowForecastingBody">
        <div className="flowForecastingText">
          <div className="flowForecastingSection">
            <h2>We forecast organic growth rates at the shareclass level</h2>
            <p>
              Backward-looking report cards of fund flows aren&apos;t going to cut
              it in the intensely competitive environment we&apos;re in today. You
              need forward-looking analytics informing your decisions to
              effectively meet investor needs before the competition siezes
              the opportunity.
            </p>
          </div>
          <div className="flowForecastingSection">
            <h2>Rigorous quantitative methodologies</h2>
            <p>
              More than a decade of research and quantitative expertise is
              embedded in our quantitative forecasting models. We use rigorous
              non-linear algorithms well-suited for modeling nuanced mutual
              fund and ETF data.
            </p>
          </div>
          <div className="flowForecastingSection">
            <h2>Fully validated predictive performance</h2>
            <p>
              Our forecasting models are battle tested. We continually assess
              their predictive performance and iteratively improve them to
              capture emerging investor preferences. Consequently, asset
              managers trust our forecasts to be accurate and actionable.
            </p>
          </div>
        </div>
        <div className="flowForecastingImageContainer">
          <img alt="Graph of fund verses a competitor over time." src={flowForecasts} />
          <img alt="Graph of organic growth verses Flowspring prediction." src={modelAccuracy} />
        </div>
      </div>
    </div>
  </Layout>
);

export default FlowForecasting;
